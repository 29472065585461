.col-1,.col-2,.col-3,.col-4,.col-5,.col-6,.col-7,.col-8,.col-9,.col-10,.col-11,.col-12 {
    display: inline-block;
}

.col-1{width: calc( 100% / 12 * 1 );}
.col-2{width: calc( 100% / 12 * 2 );}
.col-3{width: calc( 100% / 12 * 3 );}
.col-4{width: calc( 100% / 12 * 4 );}
.col-5{width: calc( 100% / 12 * 5 );}
.col-6{width: calc( 100% / 12 * 6 );}
.col-7{width: calc( 100% / 12 * 7 );}
.col-8{width: calc( 100% / 12 * 8 );}
.col-9{width: calc( 100% / 12 * 9 );}
.col-10{width: calc( 100% / 12 * 10 );}
.col-11{width: calc( 100% / 12 * 11 );}
.col-12{width: calc( 100% / 12 * 12 );}

.vertical-top {vertical-align: top;}
.text-validation {color: red;}

body #root {
    white-space: pre-line;
}

.form-wrapper {
    position: relative;
    margin-bottom: 10px;
    min-height: 54px;

    .text-validation {
        position: absolute;
        top: 0;
        right: 0;
    }

    .ring-select:not(div) {
        height: 34px;
        padding: 0 12px;
        font-size: 14px;
        line-height: 34px;
        color: #555;
        background: #fff;
        border: 1px solid #ccc;
        border-radius: 4px;
        -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
        -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
        transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;

        &:hover {
            background: #fff!important;
            box-shadow: none!important;
        }
    }

    .ring-select__icons {
        line-height: 34px!important;
    }
}

.alternatives-form-wrapper {
    position: relative;

    .text-validation {
        position: absolute;
        top: -1.3em;
        right: 0;
        font-size: 10px;
    }
}

.error-rapper {
    color: red;
    visibility: hidden;
}

.tooltip-error {
    .glyphicon {
        color: red;
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        opacity: .3;
        font-size: 10px;
    }

    &:hover .glyphicon {
        opacity: 1;
    }
}

input.hovered {
    border-color: transparent;
    outline: 2px solid #000;
}

input[disabled] {
    border-color: transparent;
    background: rgba(0,0,0,.05);
    color: rgba(0,0,0,.5);
    cursor: not-allowed!important;
}

.offsetRight-1{margin-right: calc( 100% / 12 * 1)}
.offsetRight-2{margin-right: calc( 100% / 12 * 2)}
.offsetRight-3{margin-right: calc( 100% / 12 * 3)}
.offsetRight-4{margin-right: calc( 100% / 12 * 4)}
.offsetRight-5{margin-right: calc( 100% / 12 * 5)}
.offsetRight-6{margin-right: calc( 100% / 12 * 6)}
.offsetRight-7{margin-right: calc( 100% / 12 * 7)}
.offsetRight-8{margin-right: calc( 100% / 12 * 8)}
.offsetRight-9{margin-right: calc( 100% / 12 * 9)}
.offsetRight-10{margin-right: calc( 100% / 12 * 10)}

.mr-20 {margin-right: 20px!important;}
.mr-10 {margin-right: 10px!important;}
.ml-20 {margin-left: 20px!important;}
.ml-10 {margin-left: 10px!important;}
.mb-20 {margin-bottom: 20px!important;}
.mb-10 {margin-bottom: 10px!important;}
.mt-20 {margin-top: 20px!important;}
.mt-10 {margin-top: 10px!important;}

.ring-radio + label.ring-radio__label,
.ring-checkbox *{
    box-sizing: content-box !important;
}

html, body, #root {
    height: 100%;
}

.sidebar {
    width: 210px;
    background: #1e282c;

    top: 10px;
    left: 10px;
    height: 100%;
    border-left: 3px solid transparent;

    .active {
        color: rgb(60, 141, 188) !important;
        text-decoration: underline !important;
    }

    li {
        color: #b8c7ce;
        line-height: 30px;
    }

    li:not(.submenu) {
        padding: 0 10px;
        color: #b8c7ce;
        line-height: 30px;
    }

    li a {
        color: #b8c7ce;
    }

    .submenu__wrap {
        &.open {
            .submenu__title {
                background: rgba(60, 141, 188, 0.6);

                color: #b8c7ce;
            }

            & > ul {
                background: rgba(60, 141, 188, 0.12);
            }
        }
    }

    .submenu__title {
        cursor: pointer;
        position: relative;
        padding: 0 25px 0 10px;

        //.glyphicon {
        //    font-size: 10px;
        //    position: absolute;
        //    top: 0;
        //    bottom: 0;
        //    right: 10px;
        //    line-height: inherit;
        //}
    }
}

.content {
    margin: 10px 10px 10px 10px;
}

.loader {
    position: absolute;
    width: 200px;
    height: 134px;
    top: 45px;
    right: 0;
    bottom: 0;
    left: 210px;
    margin: auto;
}